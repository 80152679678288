import { helpers } from 'common';

const { formAxiosObject } = helpers;
import { IOrderSave, IPagingParams, IReportDelivery } from 'api/order/types';

export const saveOrderAPI = async (data: IOrderSave) => {
    const response = await formAxiosObject({ method: 'POST', route: '/orders/' }, data);

    return response?.data?.data;
};

export const getOrderAPI = async (orderUUID: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/me/orders/${orderUUID}` });

    return response?.data?.data;
};

export const getOrderBuyerAPI = async (orderUUID: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/orders/${orderUUID}` });

    return response?.data?.data;
};

export const getOrdersAPI = async (params: IPagingParams, status?: number[]) => {
    const response = await formAxiosObject({ method: 'GET', route: `/me/orders/` }, null, { ...params, status: status ? `[${status?.join(',')}]` : null });

    return response?.data?.data;
};

export const getMyPurchases = async (params: IPagingParams, status?: number[]) => {
    const response = await formAxiosObject({ method: 'GET', route: `/orders/` }, null, { ...params, status: status ? `[${status?.join(',')}]` : null });

    return response?.data?.data;
};

export const getOffersAPI = async (sent?: boolean, accepted?: boolean) => {
    const response = await formAxiosObject({ method: 'GET', route: `/me/offers/` }, null, { sent: sent || false, accepted: accepted || false });

    return response?.data?.data;
};

export const getOrderDetailsAPI = async (uuid: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/me/orders` }, null, null, { uuid });

    return response?.data?.data;
};

export const getOrderDetailsPublicAPI = async (uuid: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/orders` }, null, null, { uuid });

    return response?.data?.data;
};

export const getOfferDetailsAPI = async (uuid: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/me/offers` }, null, null, { uuid });

    return response?.data?.data;
};

export const getOfferDetailsPublicAPI = async (uuid: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/offers` }, null, null, { uuid });

    return response?.data?.data;
};

export const acceptOfferAPI = async (uuid: string, productUUID: string) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/me/products/${productUUID}/offers/${uuid}` });

    return response?.data?.data;
};

export const rejectOfferAPI = async (uuid: string, productUUID: string) => {
    const response = await formAxiosObject({ method: 'DELETE', route: `/me/products/${productUUID}/offers/${uuid}` });

    return response?.data?.data;
};

export const getOfferAPI = async (uuid: string, productUUID: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/products/all/${productUUID}/offers/${uuid}` });

    return response?.data?.data;
};

export const deleteOfferAPI = async (uuid: string, productUUID: string) => {
    const response = await formAxiosObject({ method: 'DELETE', route: `/products/all/${productUUID}/offers/` });

    return response?.data?.data;
};

export const deleteAllOffersAPI = async (productUUID: string) => {
    const response = await formAxiosObject({ method: 'DELETE', route: `/products/all/${productUUID}/offers/` });

    return response?.data?.data;
};

export const getBuyerPorductOffersAPI = async (productUUID: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/products/all/${productUUID}/offers/` });

    return response?.data?.data;
};

export const acceptOrderAPI = async (uuid: string, sellerAddressUUID: string) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/me/orders/${uuid}/accept/` }, { sellerAddressUUID });

    return response?.data?.data;
};

export const rejectOrderPI = async (uuid: string, reason?: string) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/me/orders/${uuid}/decline/` }, { declineReason: reason || null });

    return response?.data?.data;
};

export const getAllCreatedOrdersThatAreNotAcceptedByProductUUID = async (productUUID: string, statuses: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/products/all/${productUUID}/orders/?statuses=${statuses}` });

    return response?.data?.data;
};

export const reportDeliveryAPI = async (orderUUID: string, deliveryUUID: string, data: IReportDelivery) => {
    const response = await formAxiosObject({ method: 'POST', route: `/orders/${orderUUID}/deliveries/${deliveryUUID}/report/` }, data);

    return response?.data?.data || {};
};
